import React from 'react';
import { Link } from 'gatsby';
import FluidImage from '../components/fluid-image.js';

const StorySummaryTemplate = ({ pageContext: { titleBanner, story, pages, content, slug } }) => {

  let Header = () => {
    if (titleBanner) {
      return (<FluidImage image={titleBanner} className='w-1/2 m-auto my-8'/>);
    } else {
      return (<h2 className='text-center text-4xl my-8 mt-16'>{story}</h2>);
    }
  };
  return (
    <section className='w-full'>
      <Header />
      <div id='introduction' dangerouslySetInnerHTML={{ __html: content }} className='my-8'/>
      <ul className='w-full flex flex-wrap justify-center mb-16'>
        {pages.map(page => {
          let image = {imageFile: page.image};
          return (
            <li key={pages.indexOf(page)} className='w-full md:w-1/3 lg:w-1/5 mx-2'>
              <Link to={`${slug}/${page.pageNumber}`}>
                <FluidImage image={image} />
              </Link>
            </li>);
        })}
      </ul>
    </section>
  );
};

export default StorySummaryTemplate;
